<template>
    <path d="m500.3 0h-47.4c-6.6 0-12 5.4-12 12v0.6l4 82.8c-47.1-55.5-116.2-87.5-188.9-87.4-136.7 0-248.1 111.5-248 248.2 0.1 136.9 111.1 247.8 248 247.8 61.4 0.1 120.7-22.7 166.2-63.9 4.9-4.4 5.4-12 0.9-16.9-0.1-0.2-0.3-0.3-0.5-0.5l-34-34c-4.5-4.5-11.6-4.7-16.4-0.5-73 64.2-184.2 57.1-248.4-15.9s-57-184.3 16-248.5 184.2-57.1 248.4 15.9c5 5.7 9.7 11.8 13.9 18.1l-101.5-4.9c-6.6-0.3-12.2 4.8-12.6 11.4v0.6 47.4c0 6.6 5.4 12 12 12h200.3c6.6 0 12-5.4 12-12v-200.3c0-6.6-5.3-12-12-12z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 512, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
